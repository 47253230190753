import { auth } from '../config/firebase-config';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const checkStripeAccount = async (accountId?: string) => {
  try {
    const user = auth.currentUser;
    if (!user) return {};

    const idToken = await user.getIdToken();
    const response = await fetch(`${API_BASE_URL}/api/payments/check-stripe-account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ accountId }), // Send accountId if available
    });

    if (!response.ok) {
      throw new Error(`Failed to check Stripe account: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error checking Stripe account:', error);
    throw error;
  }
};

export const createOrUpdateAccount = async (ownerId: string) => {
    try {
      const user = auth.currentUser;
      if (!user) return {};
  
      const idToken = await user.getIdToken();
      const response = await fetch(`${API_BASE_URL}/api/payments/create-account`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ ownerId }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to create Stripe account: ${response.statusText}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error creating Stripe account:', error);
      throw error;
    }
};

export const createLoginLink = async (accountId: string, isStripeAccountComplete: boolean) => {
  try {
    const user = auth.currentUser;
    if (!user) return {};

    const idToken = await user.getIdToken();
    const response = await fetch(`${API_BASE_URL}/api/payments/create-login-link`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ accountId,isStripeAccountComplete }),  
    });

    if (!response.ok) {
      throw new Error(`Failed to create login link: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating Stripe account:', error);
    throw error;
  }
};

export const createPaymentRedirect = async (offer:any) => {
  try {
    const user = auth.currentUser;
    if (!user) return {};

    const idToken = await user.getIdToken();
    const response = await fetch(`${API_BASE_URL}/api/payments/create-subscription-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ offer }),
    });

    if (!response.ok) {
      throw new Error('Failed to create checkout session');
    }

    const data = await response.json();
    return data;
   // Redirect to Stripe checkout
  } catch (error) {
    console.error('Error during payment redirect:', error);
    alert('Failed to initiate payment. Please try again.');
  }
};

export const getSubscription = async (lease_id: string) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      console.error("No authenticated user found.");
      return null;
    }

    const idToken = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/payments/get-subscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ lease_id}),
    });

    const data = await response.json();

    if (!response.ok) {
      console.error(`Error getting subscription: ${data.error}`);
      return null;
    }
    if (!response.ok) {
      console.error(`Error getting subscription: ${data.error}`);
      return null;
    }

    return data.subscription || null; // If subscription is null, return null
  } catch (error) {
    console.error("Error fetching subscription:", error);
    throw error;
  }
};

export const getStripePortalUrl = async (userId: string, isOwner: boolean) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      console.error("No authenticated user found.");
      return null;
    }

    const idToken = await user.getIdToken();

    const owner_id = isOwner ? userId : null;
    const guest_id = isOwner ? null : userId;
    //console.log("ownerId", ownerId, "guestId", guestId);
    const response = await fetch(`${API_BASE_URL}/api/payments/get-stripe-portal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ owner_id, guest_id }),
    });

    if (!response.ok) {
      throw new Error(`Error fetching Stripe portal: ${response.statusText}`);
    }

    const data = await response.json();
    return data.portalUrl;
  } catch (error) {
    console.error("Error fetching Stripe portal URL:", error);
    return null;
  }
};
