// src/theme/theme.ts
import { createTheme } from '@mui/material/styles';

export const brand = {
  50: '#F0F7FF',
  100: '#CEE5FD',
  200: '#9CCCFC',
  300: '#55A6F6',
  400: '#0A66C2',
  500: '#0959AA',
  600: '#064079',
  700: '#033363',
  800: '#02294F',
  900: '#021F3B',
};

export const secondary = {
  50: '#F9F0FF',
  100: '#E9CEFD',
  200: '#D49CFC',
  300: '#B355F6',
  400: '#750AC2',
  500: '#6709AA',
  600: '#490679',
  700: '#3B0363',
  800: '#2F024F',
  900: '#23023B',
};

export const gray = {
  50: '#FBFCFE',
  100: '#EAF0F5',
  200: '#D6E2EB',
  300: '#BFCCD9',
  400: '#94A6B8',
  500: '#5B6B7C',
  600: '#4C5967',
  700: '#364049',
  800: '#131B20',
  900: '#090E10',
};

export const green = {
  50: '#E6FAF0',
  100: '#BFEED7',
  200: '#89DDB4',
  300: '#47C48D',
  400: '#0D9E66',
  500: '#0A8656',
  600: '#086743',
  700: '#065136',
  800: '#043C29',
  900: '#02271C',
};

export const red = {
  50: '#FFF5F5',
  100: '#FED7D7',
  200: '#FEB2B2',
  300: '#FC8181',
  400: '#F56565',
  500: '#E53E3E',
  600: '#C53030',
  700: '#9B2C2C',
  800: '#822727',
  900: '#63171B',
};

const theme = createTheme({
  palette: {
    primary: {
      main: brand[500], // Dark blue
    },
    secondary: {
      main: brand[400], // Darker blue
    },
    background: {
      default: '#f5f5f5', // Background color
    },
  },
  breakpoints: {
    values: {
      xs: 0, // Extra small devices (0px and up)
      sm: 600, // Small devices (600px and up)
      md: 960, // Medium devices (960px and up)
      lg: 1280, // Large devices (1280px and up)
      xl: 1620, // Extra large devices (1920px and up)
    },
  },
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontSize: 60,
      fontWeight: 600,
      lineHeight: 78 / 70,
      letterSpacing: -0.2,
    },
    h2: {
      fontSize: 48,
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: 42,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: 36,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: 28,
      fontWeight: 600,
      lineHeight: 1.2,
      // color: brand[500],
    },
    h6: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: 15,
      // color: gray[800],
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 600,
      // color: gray[500],
    },
    body1: {
      fontSize: 16,
      fontWeight: 500,
      // color: brand[500],
    },
    body2: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.5,
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
    },
    button: {
      fontFamily: 'Inter, Arial, sans-serif', // Ensures buttons use Inter
      fontWeight: 400, // Makes text thicker
      textTransform: 'none', // Keeps text as written (no uppercase transformation)
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: `linear-gradient(to bottom, ${brand[100]}, #ffffff)`, // Light gradient from brand[100] to white
          minHeight: '100vh',
          scrollbarWidth: 'none', // For Firefox
          msOverflowStyle: 'none', // For Internet Explorer and Edge
          '&::-webkit-scrollbar': {
            display: 'none', // For Chrome, Safari, and Opera
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter', // Reinforce font-family
          fontWeight: 700, // Set desired weight explicitly
          borderRadius: '8px', // Rounded corners
          padding: '8px 16px', // Default padding for buttons
          boxShadow: 'none', // No box shadow
          '&:hover': {
            backgroundColor: 'transparent', // Keep background transparent on hover
          },
        },
        containedPrimary: {
          // Contained variant for primary buttons
          backgroundColor: brand[500],
          color: '#fff',
          '&:hover': {
            backgroundColor: brand[500],
          },
        },
        outlinedPrimary: {
          // Outlined variant for primary buttons
          borderColor: brand[500],
          color: brand[500],
          '&:hover': {
            backgroundColor: brand[100],
            borderColor: brand[600],
          },
        },
      },
    },
  },
});

export default theme;
