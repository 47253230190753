import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../config/firebase-config';

interface LeaseFilterProps {
  myListings: boolean;
  setMyListings: (value: boolean) => void;
  seeBids: boolean;
  setSeeBids: (value: boolean) => void;
  startDate: string;
  setStartDate: (value: string) => void;
  endDate: string;
  setEndDate: (value: string) => void;
  location: string | null;
  setLocation: (value: string | null) => void;
  numBeds: number | '';
  setNumBeds: (value: number | '') => void;
  numRoommates: number | '';
  setNumRoommates: (value: number | '') => void;
  sortPrice: string;
  setSortPrice: (value: string) => void;
  available: string;
  setAvailable: (value: string) => void;
}

function LeaseFilter({
  myListings,
  setMyListings,
  seeBids,
  setSeeBids,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  location,
  setLocation,
  numBeds,
  setNumBeds,
  numRoommates,
  setNumRoommates,
  sortPrice,
  setSortPrice,
  available,
  setAvailable,
}: LeaseFilterProps) {
  const [user] = useAuthState(auth);
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm')); // Breakpoint for mobile view
  const cities = ['Ann Arbor']; //TODO

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setOpen(open);
    };

  const disabled = myListings;
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      mb={2}
      sx={{ padding: '13px' }}
    >
      <Button
        startIcon={<FilterListIcon />}
        onClick={toggleDrawer(true)}
        variant="contained"
        sx={{ minWidth: '100px' }}
      >
        Filter
      </Button>

      <Drawer
        anchor={isMobile ? 'bottom' : 'left'} // Bottom for mobile, left for desktop
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : '300px', // Full width on mobile, narrower on desktop
            padding: '20px',
            borderRadius: isMobile ? '20px 20px 0 0' : '0', // Rounded top corners on mobile
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Filter Options</Typography>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <Button
            onClick={() => {
              setMyListings(!myListings);
              setSeeBids(false); // Disable seeBids when toggling myListings
            }}
            variant={myListings ? 'contained' : 'outlined'}
            size="small"
            sx={{
              minWidth: 'auto',
              padding: '10px 12px',
            }}
            disabled={!user}
          >
            See My Listings
          </Button>
          <Button
            onClick={() => {
              setSeeBids(!seeBids);
              setMyListings(false); // Disable myListings when toggling seeBids
            }}
            variant={seeBids ? 'contained' : 'outlined'}
            size="small"
            sx={{
              minWidth: 'auto',
              padding: '10px 12px',
            }}
            disabled={!user}
          >
            See My Active Offers
          </Button>
          <TextField
            label="Start Date"
            disabled={disabled}
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              min: new Date().toISOString().split('T')[0],
            }}
          />
          <TextField
            label="End Date"
            disabled={disabled}
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              min: new Date().toISOString().split('T')[0],
            }}
          />

          <FormControl fullWidth>
            <InputLabel>Available Beds</InputLabel>
            <Select
              value={numBeds}
              onChange={(e) => setNumBeds(e.target.value as number | '')}
              label="Available Beds"
              disabled={disabled}
            >
              <MenuItem value="">Any</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4+</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Exisiting Roomates</InputLabel>
            <Select
              value={numRoommates}
              onChange={(e) => setNumRoommates(e.target.value as number | '')}
              label="Exisiting Roomates"
              disabled={disabled}
            >
              <MenuItem value={''}>Any Roomate Situation</MenuItem>
              <MenuItem value={0}>No Roomates</MenuItem>
              {/* <MenuItem value={1}>Existing Roomates</MenuItem> */}
            </Select>
          </FormControl>

          <Autocomplete
            options={cities}
            value={location}
            onChange={(_, newValue) => setLocation(newValue)}
            renderInput={(params) => <TextField {...params} label="Location" />}
            disabled={disabled}
          />

          <FormControl fullWidth>
            <InputLabel>Sort by Price</InputLabel>
            <Select
              value={sortPrice}
              onChange={(e) => setSortPrice(e.target.value as string)}
              label="Sort by Price"
              disabled={disabled}
            >
              <MenuItem value="asc">Lowest to Highest</MenuItem>
              <MenuItem value="desc">Highest to Lowest</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Sort by Availablility</InputLabel>
            <Select
              value={available}
              onChange={(e) => setAvailable(e.target.value as string)}
              label="Sort by Availablility"
              disabled={disabled || !!startDate || !!endDate || !!sortPrice}
            >
              <MenuItem value="asc">Earliest Available</MenuItem>
              <MenuItem value="desc">Latest Available</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={toggleDrawer(false)}
          >
            Apply Filters
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => {
              setStartDate('');
              setEndDate('');
              setLocation(null);
              setNumBeds('');
              setNumRoommates('');
              setSortPrice('');
              setMyListings(false);
              setSeeBids(false);
              setAvailable('asc');
            }}
          >
            Clear Filters
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
}

export default LeaseFilter;
