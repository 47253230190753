import React, { useEffect } from 'react';
import {
  Modal,
  Box,
  Button,
  CircularProgress,
  Typography,
  Divider,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../config/firebase-config';
import { useLocation, useNavigate } from 'react-router-dom';
import { setPersistence, browserSessionPersistence } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { sendSignInUpEmail } from '../../api/notificationService';

const Login = () => {
  // 1. useAuthState to detect if user is already logged in
  const [user, userLoading] = useAuthState(auth);

  // 2. If not using react-firebase-hooks for signInWithGoogle, then import from your code
  const [signInWithGoogle, , loading, error] = useSignInWithGoogle(auth);

  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.from?.pathname || '/';

  // 3. Set local persistence so user remains logged in
  useEffect(() => {
    setPersistence(auth, browserSessionPersistence).catch((err) => {
      console.error('Error setting local persistence:', err);
    });
  }, []);

  // 4. If user is already logged in (e.g. from a previous session), skip the login modal
  useEffect(() => {
    if (!userLoading && user) {
      navigate(redirectPath, { replace: true });
    }
  }, [user, userLoading, redirectPath, navigate]);

  // 5. Your sign-in logic
  const handleSignIn = async () => {
    try {
      const userCredential = await signInWithGoogle();
      const user = userCredential?.user || null;
      if (user) {
        navigate(redirectPath, { replace: true });

        // do your background tasks
        (async () => {
          try {
            const userRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userRef);
            const isNewUser = !userDoc.exists();
            const currentTime = new Date().toLocaleString();

            const userData = isNewUser
              ? { createdAt: currentTime }
              : userDoc.data();

            await sendSignInUpEmail(
              user.uid,
              user.email || '',
              user.displayName || '',
              user.photoURL || '',
              userData?.createdAt || '',
              currentTime,
              isNewUser
            );

            if (isNewUser) {
              await setDoc(userRef, {
                uid: user.uid,
                email: user.email,
                displayName: user.displayName,
                photoURL: user.photoURL,
                createdAt: currentTime,
              });
            }
          } catch (err) {
            console.error('Error in background tasks:', err);
          }
        })();
      }
    } catch (err) {
      console.error('Error during sign-in:', err);
    }
  };

  // 6. If user is not logged in yet, show the login modal
  const handleClose = () => {
    navigate('/', { replace: true });
  };

  // If we are still checking user’s session, show a loader
  if (userLoading) {
    return (
      <Box
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  // If user is already logged in, we skip showing the modal altogether
  // The effect above takes care of redirecting, but we can short-circuit the render, too:
  if (user) {
    return null;
  }

  return (
    <Modal open onClose={handleClose} closeAfterTransition>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          maxWidth: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 3,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
          animation: 'fade-in 0.3s ease',
        }}
      >
        {/* Logo */}
        <Box
          component="img"
          src="https://d1athdg3jj0a20.cloudfront.net/assets/Dublease_Mascot.png"
          alt="Dublease Logo"
          sx={{
            width: 100,
            height: 'auto',
            mb: 2,
          }}
        />

        {/* Title */}
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Welcome to Dublease
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Sign in to explore subleasing options!
        </Typography>

        {/* Divider */}
        <Divider sx={{ width: '100%' }} />

        {/* Google Sign-In Button */}
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            onClick={handleSignIn}
            startIcon={<GoogleIcon />}
            sx={{
              backgroundColor: '#4285F4',
              color: 'white',
              textTransform: 'none',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#357ae8',
              },
              width: '100%',
              py: 1.5,
            }}
          >
            Sign in with Google
          </Button>
        )}

        {/* Error Handling */}
        {error && (
          <Typography color="error" mt={2} fontSize="0.9rem">
            {error.message || 'An error occurred. Please try again.'}
          </Typography>
        )}

        {/* Close/Cancel Button */}
        <Button
          onClick={handleClose}
          sx={{
            mt: 2,
            textTransform: 'none',
            fontWeight: 'medium',
            color: 'text.secondary',
          }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default Login;
