import React, { useEffect, useState } from 'react';
import {
  Box,
  CardContent,
  Typography,
  Grid,
  Chip,
  Button,
  Divider,
  Stepper,
  Step,
  StepLabel,
  LinearProgress,
  useTheme,
  Paper,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { auth } from '../../config/firebase-config';
import { getStripePortalUrl } from '../../api/paymentService';
import { useAuthState } from 'react-firebase-hooks/auth';
import { format, addMonths, subDays, addDays } from 'date-fns';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PaymentIcon from '@mui/icons-material/Payment';
interface SubscriptionDetails {
  host_id: string;
  guest_id: string;
  start_date: string;
  end_date: string;
  rent_price: number;
  fee_price: number;
  utilities_included: boolean;
  num_total_installments: number;
  num_installments_paid: number;
  partial_payment_price: number;
  status: string;
  billingPortalUrl: string;
}

interface PaymentSummaryTabProps {
  subscription: SubscriptionDetails;
}

const PaymentSummaryTab: React.FC<PaymentSummaryTabProps> = ({
  subscription,
}) => {
  const theme = useTheme();
  const [user] = useAuthState(auth);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [billingPortalUrl, setBillingPortalUrl] = useState('');

  const {
    start_date,
    end_date,
    rent_price,
    fee_price,
    num_total_installments,
    num_installments_paid,
    partial_payment_price,
    status,
  } = subscription;

  const is_owner = user?.uid === subscription.host_id;

  useEffect(() => {
    const fetchStripePortalUrl = async () => {
      try {
        const response = await getStripePortalUrl(user?.uid || '', is_owner);
        setBillingPortalUrl(response);
      } catch (error) {
        console.error('Error fetching Stripe portal URL:', error);
      }
    };
    fetchStripePortalUrl();
  }, [is_owner, user]);

  const monthlyFullPrice = (rent_price + fee_price) / 100;
  const partialPriceInDollars = partial_payment_price / 100;
  const leaseStart = new Date(start_date);
  const leaseEnd = new Date(end_date);

  const installmentsPaid = Math.min(
    num_installments_paid,
    num_total_installments
  );
  const progressPercentage = (installmentsPaid / num_total_installments) * 100;

  const generateBillingPeriods = (
    startDate: string,
    endDate: string,
    totalInstallments: number
  ) => {
    const periods = [];
    let current = new Date(startDate);

    for (let i = 0; i < totalInstallments; i++) {
      let periodStart = new Date(current);
      let periodEnd = subDays(addMonths(periodStart, 1), 1);
      let payoutDate = addDays(periodStart, 2);
      // Adjust last period if lease ends mid-month
      if (periodEnd > new Date(endDate)) {
        periodEnd = new Date(endDate);
        //payoutDate = addDays(periodStart, 2);
      }

      periods.push({
        monthLabel: `Month ${i + 1}`,
        billingPeriod: `${format(periodStart, 'MMM d')} - ${format(
          periodEnd,
          'MMM d, yyyy'
        )}`,
        payoutDate: format(payoutDate, 'MMM d, yyyy'),
        amount:
          i === totalInstallments - 1 && partial_payment_price > 0
            ? partialPriceInDollars
            : monthlyFullPrice,
        isPaid: i < installmentsPaid,
        isNext: i === installmentsPaid,
      });

      current = addMonths(current, 1);
    }

    return periods;
  };

  const billingPeriods = generateBillingPeriods(
    start_date,
    end_date,
    num_total_installments
  );

  const handleOpenBillingPortal = () => {
    window.open(billingPortalUrl, '_blank');
  };
  const LeaseInfoCard = ({ title, date }: { title: string; date: Date }) => (
    <Paper elevation={2} sx={{ p: 1, borderRadius: 2, height: '100%' }}>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        {title}
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <CalendarTodayIcon color="primary" fontSize="small" />
        <Typography variant="subtitle1" fontWeight="500">
          {format(date, 'MMM d, yyyy')}
        </Typography>
      </Stack>
    </Paper>
  );

  const StatusChip = ({
    label,
    color,
  }: {
    label: string;
    color: 'primary' | 'success' | 'error';
  }) => (
    <Chip
      label={label}
      color={color}
      size={isMobile ? 'small' : 'medium'}
      sx={{ fontWeight: 'bold', my: 1 }}
    />
  );

  const PaymentPeriodCard = ({ period }: { period: any }) => (
    <Paper
      elevation={2}
      sx={{
        p: 1,
        borderRadius: 2,
        mb: 2,
        backgroundColor: period.isPaid
          ? theme.palette.success.light
          : theme.palette.background.default,
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3}>
          <Typography variant="body1" fontWeight="bold">
            {period.monthLabel}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Chip
            label={period.isPaid ? (is_owner ? 'Recieved' : 'Paid') : 'Upcoming'}
            color={period.isPaid ? 'success' : 'default'}
            size={isMobile ? 'small' : 'medium'}
          />
        </Grid>
        {/* <Grid item xs={12} sm={3}>
          <Typography variant="body2">{period.billingPeriod}</Typography>
        </Grid> */}
        <Grid item xs={12} sm={3}>
          <Typography variant="body2" fontWeight="bold">
            {is_owner
              ? `Payout: ${period.payoutDate}`
              : `Due: ${period.payoutDate}`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="h6" align="right">
            ${period.amount.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Box sx={{ margin: '0 auto' }}>
      <CardContent>
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} sm={6}>
            <LeaseInfoCard title="Lease Start" date={leaseStart} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LeaseInfoCard title="Lease End" date={leaseEnd} />
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
          <StatusChip
            label={
              subscription.utilities_included
                ? 'Utilities Included'
                : 'Utilities Not Included'
            }
            color="primary"
          />
          <StatusChip
            label={`Status: ${status}`}
            color={status === 'active' ? 'success' : 'error'}
          />
        </Stack>

        <Divider sx={{ my: 3 }} />

        <Typography variant="body1" fontWeight="bold" gutterBottom>
          Installments {is_owner ? 'Received' : 'Paid'}: {installmentsPaid} /{' '}
          {num_total_installments}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={progressPercentage}
          sx={{ height: 10, borderRadius: 5, my: 2 }}
        />

        <Typography variant="h6" fontWeight="bold" gutterBottom>
          {is_owner ? 'Payout Schedule' : 'Payment Schedule'}
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph>
          {is_owner
            ? 'You will receive payments 2 days after the billing period ends.'
            : 'Payments are due monthly, covering the billing period stated below.'}
        </Typography>

        {billingPeriods.map((period, index) => (
          <PaymentPeriodCard key={index} period={period} />
        ))}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<PaymentIcon />}
          sx={{ borderRadius: 2, py: 1.5, fontWeight: 'bold', mt: 3 }}
          onClick={handleOpenBillingPortal}
        >
          {is_owner ? 'Manage Payouts' : 'Edit Payment Method'}
        </Button>
      </CardContent>
    </Box>
  );
  // return (
  //   <Box sx={{ margin: '0 auto', borderRadius: 3, p: 3 }}>
  //     <CardContent>
  //       {/* Lease Dates */}
  //       <Grid container spacing={2} justifyContent="space-between">
  //         <Grid item xs={6}>
  //           <Typography variant="body2" color="text.secondary">
  //             Lease Start
  //           </Typography>
  //           <Typography variant="subtitle1" fontWeight="500">
  //             {format(leaseStart, 'MMM d, yyyy')}
  //           </Typography>
  //         </Grid>
  //         <Grid item xs={6} textAlign="right">
  //           <Typography variant="body2" color="text.secondary">
  //             Lease End
  //           </Typography>
  //           <Typography variant="subtitle1" fontWeight="500">
  //             {format(leaseEnd, 'MMM d, yyyy')}
  //           </Typography>
  //         </Grid>
  //       </Grid>

  //       {/* Status & Utilities */}
  //       <Grid container spacing={2} mt={1} alignItems="center">
  //         <Grid item xs={6}>
  //           <Chip
  //             label={
  //               subscription.utilities_included
  //                 ? 'Utilities Included'
  //                 : 'Utilities Not Included'
  //             }
  //             color="primary"
  //           />
  //         </Grid>
  //         <Grid item xs={6} textAlign="right">
  //           <Chip
  //             label={`Status: ${status}`}
  //             color={status === 'active' ? 'success' : 'error'}
  //           />
  //         </Grid>
  //       </Grid>

  //       <Divider sx={{ my: 2 }} />

  //       {/* Progress Bar */}
  //       <Typography variant="body2" color="text.secondary">
  //         Installments {is_owner ? 'Recieved' : 'Paid'}: {installmentsPaid} /{' '}
  //         {num_total_installments}
  //       </Typography>
  //       <LinearProgress
  //         variant="determinate"
  //         value={progressPercentage}
  //         sx={{ height: 10, borderRadius: 5, mt: 1, mb: 3 }}
  //       />

  //       {/* Payment Schedule */}
  //       <Typography variant="h6" fontWeight="bold" gutterBottom>
  //         {is_owner ? 'Payout Schedule' : 'Payment Schedule'}
  //       </Typography>
  //       <Typography variant="body2" color="text.secondary" paragraph>
  //         {is_owner
  //           ? 'You will receive payments 2 days after the billing period ends.'
  //           : 'Payments are due monthly, covering the billing period stated below.'}
  //       </Typography>

  //       {/* Payment Details */}
  //       {billingPeriods.map((period, index) => (
  //         <Box
  //           key={index}
  //           display="flex"
  //           justifyContent="space-between"
  //           alignItems="center"
  //           gap={2}
  //           py={1}
  //           px={2}
  //           sx={{
  //             borderRadius: 2,
  //             mb: 1,
  //             backgroundColor: period.isNext
  //               ? 'rgba(255, 193, 7, 0.2)'
  //               : period.isPaid
  //               ? 'rgba(76, 175, 80, 0.2)'
  //               : 'transparent',
  //           }}
  //         >
  //           <Typography variant="body1">{period.monthLabel}</Typography>
  //           <Chip
  //             label={period.isPaid ? 'Paid' : 'Upcoming'}
  //             color={period.isPaid ? 'success' : 'default'}
  //           />
  //           <Typography variant="body1">{period.billingPeriod}</Typography>
  //           <Typography variant="body1" fontWeight="bold">
  //             {is_owner
  //               ? `Payout: ${period.payoutDate}`
  //               : `Due: ${period.payoutDate}`}
  //           </Typography>
  //           <Typography variant="body1">${period.amount.toFixed(2)}</Typography>
  //         </Box>
  //       ))}

  //       <Divider sx={{ mt: 3, mb: 2 }} />

  //       {/* Manage Payment */}
  //       <Button
  //         variant="contained"
  //         color="primary"
  //         fullWidth
  //         sx={{ borderRadius: 2, py: 1.5, fontWeight: 'bold' }}
  //         onClick={handleOpenBillingPortal}
  //       >
  //         {is_owner ? 'Manage Payouts' : 'Edit Payment Method'}
  //       </Button>
  //     </CardContent>
  //   </Box>
  // );
};

export default PaymentSummaryTab;
