import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  TextField,
  Button,
  IconButton,
  Paper,
  Skeleton,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { auth } from '../../config/firebase-config';
import { getUserProfile } from '../../api/userService';
import { brand, gray } from '../../theme/theme';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  deleteInteraction,
  getBid,
  getChatHistory,
  sendChatMessage,
} from '../../api/interactionService';
import io from 'socket.io-client';
import ReviewForm from './ReviewForm';
import ConfirmationDialog from '../common/confirmationDialog/ConfirmationDeleteDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatChatTimestamp } from '../utils/formatTimestamps';
import { useActionMessage } from '../common/actionMessage/ActionMessage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { createPaymentRedirect } from '../../api/paymentService';
import AcceptOfferForm from './AcceptOffer';

const socket = io(process.env.REACT_APP_API_BASE_URL as string, {
  // withCredentials: true, // Ensure cookies are sent if needed
});

interface ChatProps {
  chatId: string;
  leaseId: string;
  isSubscriptionActive: boolean;
}

const Chat = ({ chatId, leaseId, isSubscriptionActive }: ChatProps) => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  const { showMessage } = useActionMessage();
  const [chatData, setChatData] = useState({
    ownerId: '',
    bidderId: '',
    messages: [] as any[],
    otherUserProfile: null as any,
    currentOffer: null as any,
  });
  const [newMessage, setNewMessage] = useState('');
  const [isReviewing, setIsReviewing] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);

  // Function to scroll to the bottom of the chat
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  if (!user) {
    navigate('/Login');
  }

  const fetchChatData = async () => {
    try {
      setLoading(true);
      const { chat, owner_id, bidder_id } = await getChatHistory(chatId);
      const profileIdToFetch = user?.uid === owner_id ? bidder_id : owner_id;
      const profileData = await getUserProfile(profileIdToFetch);
      const currentOffer =
        owner_id && bidder_id && leaseId
          ? await getBid(owner_id, bidder_id, leaseId)
          : null;

      setChatData({
        ownerId: owner_id,
        bidderId: bidder_id,
        messages: chat.map((msg: any) => ({
          ...msg,
          formattedTimestamp: formatChatTimestamp(msg.timestamp),
        })),
        otherUserProfile: profileData,
        currentOffer,
      });
    } catch (error) {
      console.error('Error loading chat data:', error);
    } finally {
      setLoading(false);
    }
  };
  // Fetch & listen for chat data
  useEffect(() => {
    fetchChatData();
    socket.emit('joinChat', chatId);
    socket.on('new_message', (newMsg) => {
      const formattedMessage = {
        ...newMsg,
        formattedTimestamp: formatChatTimestamp(newMsg.timestamp),
      };
      setChatData((prevData) => ({
        ...prevData,
        messages: [...prevData.messages, formattedMessage],
      }));

      if (newMsg.message?.includes('Offer is official!')) {
        fetchChatData();
      }
      scrollToBottom();
    });

    return () => {
      socket.off('new_message');
    };
  }, [chatId, user, leaseId]);

  // Scroll to the bottom of the chat when new messages are added
  useEffect(() => {
    if (chatData.messages.length || newMessage) {
      scrollToBottom();
    }
  }, [chatData.messages, newMessage]);
  //Navigation & routing
  useEffect(() => {
    // Check if user is on the payment form route
    if (location.pathname.includes('/proceed-to-payment')) {
      setIsAccepting(true);
    } else if (location.pathname.includes('/make-official-offer')) {
      setIsReviewing(true);
    } else {
      setIsReviewing(false);
      setIsAccepting(false);
    }
  }, [location.pathname]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      const user = auth.currentUser;
      if (user) {
        await sendChatMessage({
          chat_id: chatId,
          owner_id: chatData.ownerId,
          bidder_id: chatData.bidderId,
          lease_id: leaseId,
          sender_id: user.uid,
          message: newMessage,
          is_a_bid: false,
          // is_info_msg: false,
          timestamp: new Date().toISOString(),
        });
        setNewMessage('');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleDeleteInteraction = async () => {
    try {
      await deleteInteraction(
        chatId,
        chatData.ownerId,
        chatData.bidderId,
        leaseId
      );
      setDeleteDialogOpen(false);
      showMessage('Chat deleted, refresh to see changes', 'success');
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  const callBackToChat = () => {
    scrollToBottom();
    setIsReviewing(false);
    setIsAccepting(false);
  };

  const handlePaymentRedirect = async () => {
    try {
      setPaymentLoading(true);
      const data = await createPaymentRedirect(chatData.currentOffer);
      window.location.href = data.url;
    } catch (error) {
      console.error('Error redirecting to payment:', error);
    } finally {
      setPaymentLoading(false);
    }
  };

  // Function for chat skeleton
  const renderChatSkeleton = () => (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '5px',
          backgroundColor: gray[100],
          borderBottom: '1px solid #ccc',
        }}
      >
        <Skeleton variant="circular" width={56} height={56} />
        <Skeleton variant="text" width={100} height={20} />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          padding: '16px',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {Array.from({ length: 5 }).map((_, index) => (
          <Box
            key={index}
            sx={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Skeleton
              variant="rectangular"
              width="75%"
              height={50}
              sx={{ mb: 2, borderRadius: '10px' }}
            />
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '16px',
          borderTop: '1px solid #ccc',
        }}
      >
        <Skeleton variant="rectangular" width="85%" height={50} />
        <Skeleton variant="rectangular" width={75} height={50} sx={{ ml: 2 }} />
      </Box>
    </>
  );

  return (
    <>
      {loading ? (
        renderChatSkeleton()
      ) : (
        <>
          {chatData.messages.length > 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                maxHeight: { xs: '93vh', sm: '60vh' },
                //border: `1px solid ${brand[500]}`,
                borderRadius: '10px',
                padding: 1,
              }}
            >
              {/* Top Section: Other user's profile info */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '10px 16px',
                  backgroundColor: brand[50],
                  borderBottom: '1px solid black',
                }}
              >
                {/* Back Button */}
                <Button
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate(-1)}
                  sx={{
                    height: '40px',
                    textTransform: 'none',
                  }}
                >
                  Back
                </Button>

                {/* Centered Profile Section */}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    src={chatData.otherUserProfile?.profile_picture_url}
                    alt={chatData.otherUserProfile?.first_name}
                    sx={{ width: 56, height: 56 }}
                  />
                  <Typography variant="h6" sx={{ mt: 0.5 }}>
                    {chatData.otherUserProfile?.first_name}
                  </Typography>
                </Box>

                {/* Withdraw Button */}
                <Button
                  color="inherit"
                  onClick={() => setDeleteDialogOpen(true)}
                  sx={{
                    height: '40px',
                    textTransform: 'none',
                  }}
                >
                  Withdraw
                </Button>
              </Box>

              {isReviewing && (
                <ReviewForm
                  chatId={chatId}
                  ownerId={chatData.ownerId}
                  bidderId={chatData.bidderId}
                  leaseId={leaseId}
                  currentOffer={chatData.currentOffer}
                  callBackToChat={callBackToChat}
                /> // Use the ReviewForm component
              )}
              {isAccepting && (
                <AcceptOfferForm
                  currentOffer={chatData.currentOffer}
                  onProceedToPayment={handlePaymentRedirect}
                  loading={paymentLoading}
                  onCancel={callBackToChat}
                />
              )}
              {!isReviewing && !isAccepting && (
                <>
                  {/* Chat History */}
                  <Box
                    sx={{
                      flexGrow: 1,
                      padding: '16px',
                      overflowY: 'scroll',
                      display: 'flex',
                      flexDirection: 'column',
                      // bgcolor: brand[50],
                      gap: 1,
                    }}
                  >
                    {loading ? (
                      <Typography>Loading chat...</Typography>
                    ) : (
                      chatData.messages.map((msg, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: 'flex',
                            justifyContent:
                              msg.is_a_bid || msg.is_info_msg
                                ? 'center'
                                : msg.sender_id === auth.currentUser?.uid
                                ? 'flex-end'
                                : 'flex-start',
                            width: '100%',
                          }}
                        >
                          {/* Conditional rendering for is_a_bid */}
                          {msg.is_a_bid || msg.is_info_msg ? (
                            <Typography
                              variant="body2"
                              sx={{
                                color: msg.is_info_msg ? gray[800] : brand[500],
                                fontStyle: 'italic',
                                textAlign: 'center',
                                padding: '2px 0',
                              }}
                            >
                              {msg.message}
                            </Typography>
                          ) : (
                            <Paper
                              sx={{
                                padding: '8px 8px 1px 8px',
                                alignSelf:
                                  msg.sender_id === auth.currentUser?.uid
                                    ? 'flex-end'
                                    : 'flex-start',
                                backgroundColor:
                                  msg.sender_id === auth.currentUser?.uid
                                    ? brand[500]
                                    : gray[600],
                                color: 'white',
                                maxWidth: '75%',
                              }}
                            >
                              <Typography variant="body2" gutterBottom>
                                {msg.message}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{ fontStyle: 'italic' }}
                              >
                                {msg.formattedTimestamp}
                              </Typography>
                            </Paper>
                          )}
                        </Box>
                      ))
                    )}
                    <div ref={chatEndRef}></div>
                  </Box>

                  {/* Bottom Section: Input bar and buttons */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '16px',
                      borderTop: '1px solid #ccc',

                      gap: '16px', // Uniform spacing between elements
                    }}
                  >
                    {/* Message Input */}
                    <TextField
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          // Send on Enter, not Shift+Enter
                          e.preventDefault(); // Prevent adding a newline
                          handleSendMessage();
                        }
                      }}
                      multiline
                      maxRows={3}
                      variant="outlined"
                      fullWidth
                      placeholder="Type your message..."
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            color="primary"
                            onClick={handleSendMessage}
                            disabled={!newMessage.trim()}
                            sx={{
                              '&:hover': {
                                transform: 'scale(1.2)',
                              },
                              transition: 'transform 0.2s ease-in-out',
                            }}
                          >
                            <SendIcon />
                          </IconButton>
                        ),
                      }}
                      sx={{
                        '.MuiOutlinedInput-root': {
                          paddingRight: 0,
                        },
                      }}
                    />

                    {/* Submit/Edit Offer Button */}
                    {user?.uid === chatData.ownerId ? (
                      // <Tooltip title="Feature Coming Soon" arrow>
                      //   <span>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={false}
                        //disabled={true}
                        sx={{
                          height: '48px', // Consistent height with TextField
                          padding: '8px 16px',
                          borderRadius: '8px',
                          whiteSpace: 'nowrap', // Prevent button text from wrapping
                        }}
                        onClick={() =>
                          isSubscriptionActive
                            ? navigate(`/posts/${leaseId}/payments`)
                            : navigate(
                                `/posts/${leaseId}/chat/${chatId}/make-official-offer`
                              )
                        }
                      >
                        {isSubscriptionActive
                          ? 'Check Progress'
                          : 'Official Offer'}
                      </Button>
                    ) : (
                      //   </span>
                      // </Tooltip>
                      // <Tooltip title="Feature Coming Soon" arrow>
                      //   <span>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!chatData.currentOffer?.is_live}
                        //disabled={true}
                        sx={{
                          height: '48px', // Consistent height with TextField
                          padding: '8px 16px',
                          borderRadius: '8px',
                          whiteSpace: 'nowrap', // Prevent button text from wrapping
                        }}
                        onClick={() =>
                          isSubscriptionActive
                            ? navigate(`/posts/${leaseId}/payments`)
                            : navigate(
                                `/posts/${leaseId}/chat/${chatId}/proceed-to-payment`
                              )
                        }
                      >
                        {isSubscriptionActive
                          ? 'Check Progress'
                          : 'Accept Offer'}
                      </Button>
                      //   </span>
                      // </Tooltip>
                    )}
                  </Box>
                </>
              )}

              <ConfirmationDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={handleDeleteInteraction}
                title="Are you sure you want to withdraw? This chat and all progress will be deleted."
                confirmText="Delete"
                cancelText="Cancel"
              />
            </Box>
          ) : (
            <Typography variant="body2" color="text.secondary">
              You must make an offer to chat with the host.
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default Chat;
