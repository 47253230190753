import {
  WifiPassword,
  FitnessCenter as FitnessCenterIcon,
  Pool as PoolIcon,
  Weekend as WeekendIcon,
  Air as AirIcon,
  LocalLaundryService as LocalLaundryServiceIcon,
  Kitchen as KitchenIcon,
  Pets as PetsIcon,
  LocalParking as LocalParkingIcon,
} from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Lease } from '../../types/Lease';
import { brand } from '../../../theme/theme';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../config/firebase-config';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../../common/confirmationDialog/ConfirmationDeleteDialog';
import { useActionMessage } from '../../common/actionMessage/ActionMessage';
import GoogleMap from '../../utils/MapsComponent';
import UserDetailsTab from './UserDetailTab';
import PlaceBid from '../../bids/PlaceBid';
import { formatDate } from '../../utils/formatTimestamps';
import { checkProfileCompletion } from '../../../api/userService';
import { saveInitialInteraction } from '../../../api/interactionService';
import DistanceCalculator from './DistanceCalculator ';
import { deleteLease } from '../../../api/leaseService';

interface LeaseDetailsTabProps {
  lease: Lease;
  bidData: any;
  bidExists: boolean;
  subscribed: boolean;
  onFormChange: () => void;
  goToChat: (chat_id?: string) => void;
}

const LeaseDetailsTab = ({
  lease,
  bidData,
  bidExists,
  onFormChange,
  subscribed,
  goToChat,
}: LeaseDetailsTabProps) => {
  const [user] = useAuthState(auth);
  const isOwner = user?.uid === lease.owner_id;
  const navigate = useNavigate();
  const { showMessage } = useActionMessage();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [showBanner, setShowBanner] = useState(true);

  const placeBidRef = useRef<HTMLDivElement>(null);
  const modalBottomRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  // Handle Scrolling For Banner
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const placeBidEntry = entries.find(
          (entry) => entry.target === placeBidRef.current
        );
        const modalBottomEntry = entries.find(
          (entry) => entry.target === modalBottomRef.current
        );

        if (modalBottomEntry) {
          setShowBanner(!modalBottomEntry.isIntersecting); // Hide banners when at the bottom
        } else if (placeBidEntry) {
          setShowBanner(!placeBidEntry.isIntersecting); // Show/hide banner based on placeBid visibility
        }
      },
      { threshold: 1 }
    );

    if (placeBidRef.current) observer.observe(placeBidRef.current);
    if (modalBottomRef.current) observer.observe(modalBottomRef.current);

    return () => observer.disconnect();
  }, []);

  const scrollToPlaceBid = () => {
    if (placeBidRef.current) {
      placeBidRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Delete the post and close the dialog
  const handleDeleteConfirm = async () => {
    if (lease && user && user?.uid === lease.owner_id) {
      try {
        const response = await deleteLease(lease?.id || '');
        onFormChange();
        showMessage(response.message, 'success');
        navigate('/');
      } catch (error) {
        console.error('Error deleting post:', error);
        showMessage('Error deleting post. Please try again.', 'error');
      }
    }
  };

  const submitBid = async (
    startDate: string,
    endDate: string,
    price: string,
    priceUnit: string,
    numBeds: number,
    additionalDetails: string
  ) => {
    setLoading(true);

    // Check if the user's profile is complete
    const isProfileComplete = await checkProfileCompletion();
    if (!isProfileComplete) {
      showMessage('You must create a profile to make an offer', 'error');
      navigate('/Profile');
      setLoading(false);
      return;
    }

    try {
      const bidData = {
        owner_id: lease.owner_id || '',
        bidder_id: user?.uid || '',
        lease_id: lease.id || '',
        start_date: startDate,
        end_date: endDate,
        price: parseFloat(price),
        price_unit: priceUnit,
        num_beds_for_bid: numBeds,
        additional_details: additionalDetails,
      };

      const chat_id = await saveInitialInteraction(bidData);
      onFormChange(); // Refresh the page to show the active offer

      if (chat_id) {
        goToChat(chat_id);
        showMessage('Offer submitted successfully!', 'success');
      } else {
        throw new Error('Failed to submit offer');
      }
    } catch (error: any) {
      showMessage(`Error: ${error.message}`, 'error');
    } finally {
      setLoading(false);
    }
  };
  // Amenities
  const amenitiesList = [
    {
      label: 'WiFi',
      icon: <WifiPassword sx={{ fontSize: 'medium', mr: '5px' }} />,
    },
    {
      label: 'Gym',
      icon: <FitnessCenterIcon sx={{ fontSize: 'medium', mr: '5px' }} />,
    },
    {
      label: 'Pool',
      icon: <PoolIcon sx={{ fontSize: 'medium', mr: '5px' }} />,
    },
    {
      label: 'Furnished',
      icon: <WeekendIcon sx={{ fontSize: 'medium', mr: '5px' }} />,
    },
    { label: 'AC', icon: <AirIcon sx={{ fontSize: 'medium', mr: '5px' }} /> },
    {
      label: 'Laundry',
      icon: <LocalLaundryServiceIcon sx={{ fontSize: 'medium', mr: '5px' }} />,
    },
    {
      label: 'Dishwasher',
      icon: <KitchenIcon sx={{ fontSize: 'medium', mr: '5px' }} />,
    },
    {
      label: 'Pet Friendly',
      icon: <PetsIcon sx={{ fontSize: 'medium', mr: '5px' }} />,
    },
    {
      label: 'Parking',
      icon: <LocalParkingIcon sx={{ fontSize: 'medium', mr: '5px' }} />,
    },
  ];

  const filteredAmenities = amenitiesList.filter((amenity) =>
    lease.amenities?.includes(amenity.label)
  );

  return (
    <>
      <Box>
        {/* Images */}
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={0}
          slidesPerView={1}
          loop
          style={{ width: '100%', height: '400px' }} // Adjust height as needed
        >
          {lease.image_urls && lease.image_urls.length > 0 ? (
            lease.image_urls.map((image, idx) => (
              <SwiperSlide key={idx}>
                <img
                  src={image}
                  alt={`Lease ${idx + 1}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    borderRadius: '10px 10px 0 0',
                  }}
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <img
                src="/No_Image_Available.jpg"
                alt="User uploaded 0 images"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '10px 10px 0 0',
                }}
              />
            </SwiperSlide>
          )}
        </Swiper>

        {/* Details */}
        <Box sx={{ mt: 2, p: 2, bgcolor: '#EAF0F5', borderRadius: '10px' }}>
          <Grid container spacing={2}>
            {/* Lease Details */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: brand[500] }} gutterBottom>
                Lease Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Start Date
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatDate(lease.start_date)}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Price
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    ${lease.price} / {lease.price_unit}
                    {' - '}
                    {lease?.utilities_included_in_price
                      ? 'Utilities Included'
                      : 'Utilities Not Included'}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    End Date
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatDate(lease.end_date)}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Rooms For Lease
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.num_rooms_for_lease} Bedroom(s) and{' '}
                    {lease.num_bathrooms} Bathroom(s)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* User Details */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: brand[500] }} gutterBottom>
                User Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <UserDetailsTab lease={lease} />
            </Grid>
            {/* Additional Details */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: brand[500] }} gutterBottom>
                Additional Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    House Gender
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.num_roommates_present_during_dublease === 0
                      ? 'Open To All - 0 Roomates Present'
                      : lease.housing_gender}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Roommates Present During Sublease
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.num_roommates_present_during_dublease}
                  </Typography>
                </Grid>
              </Grid>
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: brand[500],
                  }}
                >
                  Amenities
                </Typography>
                <Grid container spacing={2}>
                  {filteredAmenities.length > 0 ? (
                    filteredAmenities.map((amenity, idx) => (
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        key={idx} /* Adjust to stack into 3 columns */
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            // gap: 1,
                            m: '5px', // Reduced padding for snug fit
                          }}
                        >
                          {amenity.icon} {amenity.label}
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 2,
                      }}
                    >
                      No Amenities Provided
                    </Typography>
                  )}
                </Grid>
              </Box>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: brand[500] }}
                  gutterBottom
                >
                  Description
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {lease.description
                    ? lease.description
                    : 'No Description Provided'}
                </Typography>
              </Grid>
            </Grid>
            {/* Location */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: brand[500] }} gutterBottom>
                Location
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <GoogleMap address={lease.address} />
              {/* Distance Calculation Section */}
              <DistanceCalculator destination={lease.address} />
            </Grid>
          </Grid>
        </Box>

        {/* PlaceBid Section */}
        {!isOwner && (
          <Box ref={placeBidRef} sx={{ mt: 4 }}>
            <PlaceBid
              lease={lease}
              bidData={bidData}
              bidExists={bidExists}
              submitBid={submitBid}
              loading={loading}
            />
          </Box>
        )}

        <Box ref={modalBottomRef} sx={{ height: '1px' }} />
        {/* Delete & Edit Button */}
        {lease.owner_id === user?.uid && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              padding: '10px 0px 0px 0px',
              gap: '20px',
            }}
          >
            <Button
              variant="text"
              sx={{ color: 'grey' }}
              size="medium"
              onClick={() => setDeleteDialogOpen(true)}
            >
              Delete My Post
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              onClick={() =>
                navigate('/ListYourPlace', { state: { post: lease } })
              }
            >
              Edit Post
            </Button>
          </Box>
        )}

        {/* Make An Offer Banner */}
        {showBanner && !bidExists && !subscribed && !isOwner && user && (
          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              left: 0,
              width: '95%', // Slightly inset to give it a modern look
              margin: '0 auto', // Center the banner horizontally
              bgcolor: brand[50], // Light, minimal background
              borderRadius: '12px', // Softer corners for a modern feel
              border: `2px solid ${brand[300]}`, // Subtle border with brand color
              color: '#003366', // Darker blue text for contrast
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px 16px', // Comfortable padding
              zIndex: 1000,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Slightly stronger shadow for depth
              backdropFilter: 'blur(8px)', // Subtle blur for a polished effect
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: { xs: '0.9rem', md: '1.1rem' },
                fontWeight: 500,
              }}
            >
              Interested?
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={scrollToPlaceBid}
              sx={{
                fontSize: { xs: '0.8rem', md: '1rem' },
                padding: { xs: '6px 12px', md: '8px 16px' },
                boxShadow: 'none', // Clean and minimal button
              }}
            >
              Offer & Chat
            </Button>
          </Box>
        )}

        {/* See offers */}
        {showBanner && isOwner && !subscribed && user && (
          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              left: 0,
              width: '95%', // Slightly inset to give it a modern look
              margin: '0 auto', // Center the banner horizontally
              bgcolor: brand[50], // Light, minimal background
              borderRadius: '12px', // Softer corners for a modern feel
              border: `2px solid ${brand[300]}`, // Subtle border with brand color
              color: '#003366', // Darker blue text for contrast
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px 16px', // Comfortable padding
              zIndex: 1000,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Slightly stronger shadow for depth
              backdropFilter: 'blur(8px)', // Subtle blur for a polished effect
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: { xs: '0.9rem', md: '1.1rem' },
                fontWeight: 500,
              }}
            >
              Check Incoming Offers
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/posts/${lease.id}/incoming-offers`)}
              sx={{
                fontSize: { xs: '0.8rem', md: '1rem' },
                padding: { xs: '6px 12px', md: '8px 16px' },
                boxShadow: 'none', // Clean and minimal button
              }}
            >
              See Offers
            </Button>
          </Box>
        )}

        {/* Go To Chat - User Placed A Bid*/}
        {showBanner && bidExists && !subscribed && !isOwner && user && (
          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              left: 0,
              width: '95%', // Slightly inset to give it a modern look
              margin: '0 auto', // Center the banner horizontally
              bgcolor: brand[50], // Light, minimal background
              borderRadius: '12px', // Softer corners for a modern feel
              border: `2px solid ${brand[300]}`, // Subtle border with brand color
              color: '#003366', // Darker blue text for contrast
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px 16px', // Comfortable padding
              zIndex: 1000,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Slightly stronger shadow for depth
              backdropFilter: 'blur(8px)', // Subtle blur for a polished effect
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: { xs: '0.9rem', md: '1.1rem' },
                fontWeight: 500,
              }}
            >
              Chat With Host
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                navigate(`/posts/${lease.id}/chat/${bidData.chat_id}`)
              }
              sx={{
                fontSize: { xs: '0.8rem', md: '1rem' },
                padding: { xs: '6px 12px', md: '8px 16px' },
                boxShadow: 'none', // Clean and minimal button
              }}
            >
              Chat
            </Button>
          </Box>
        )}

        {/* Go To Chat - User Placed A Bid*/}
        {showBanner && subscribed && user && (
          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              left: 0,
              width: '95%', // Slightly inset to give it a modern look
              margin: '0 auto', // Center the banner horizontally
              bgcolor: brand[50], // Light, minimal background
              borderRadius: '12px', // Softer corners for a modern feel
              border: `2px solid ${brand[300]}`, // Subtle border with brand color
              color: '#003366', // Darker blue text for contrast
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px 16px', // Comfortable padding
              zIndex: 1000,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Slightly stronger shadow for depth
              backdropFilter: 'blur(8px)', // Subtle blur for a polished effect
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: { xs: '0.9rem', md: '1.1rem' },
                fontWeight: 500,
              }}
            >
              {isOwner ? 'Check Incoming Installments' : 'Check Your Payments'}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/posts/${lease.id}/payments`)}
              sx={{
                fontSize: { xs: '0.8rem', md: '1rem' },
                padding: { xs: '6px 12px', md: '8px 16px' },
                boxShadow: 'none', // Clean and minimal button
              }}
            >
              Payments
            </Button>
          </Box>
        )}

        <ConfirmationDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onConfirm={handleDeleteConfirm}
          title="Are you sure you want to delete this post? Deleting the post will delete all offers and chats."
          confirmText="Delete"
          cancelText="Cancel"
        />
      </Box>
    </>
  );
};

export default LeaseDetailsTab;
