import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
  InputAdornment,
} from '@mui/material';
import { rescindLiveOffers, makeOffer } from '../../api/interactionService';
import { useActionMessage } from '../common/actionMessage/ActionMessage';
import { AttachMoney, WarningAmberOutlined } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { createOrUpdateAccount } from '../../api/paymentService';
import EmbeddedStripeOnboarding from '../payments/StripeOnboarding';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { brand } from '../../theme/theme';

interface ReviewFormProps {
  ownerId: string;
  bidderId: string;
  leaseId: string;
  chatId: string;
  currentOffer: any;
  callBackToChat: () => void;
}

const ReviewForm = ({
  ownerId,
  bidderId,
  leaseId,
  chatId,
  currentOffer,
  callBackToChat,
}: ReviewFormProps) => {
  const [startDate, setStartDate] = useState(
    currentOffer?.start_date.split('T')[0] || ''
  );
  const [endDate, setEndDate] = useState(
    currentOffer?.end_date.split('T')[0] || ''
  );
  const [price, setPrice] = useState(currentOffer?.price || '');
  const [priceUnit, setPriceUnit] = useState(
    currentOffer?.price_unit || 'Total'
  );
  const [numBeds, setNumBeds] = useState(currentOffer?.num_beds_for_bid || '');
  const [selectBeds, setSelectBeds] = useState(
    currentOffer?.num_beds_for_bid || 1
  );
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [stripeAccountId, setStripeAccountId] = useState('');
  const [isStripeAccountComplete, setIsStripeAccountComplete] = useState(false);
  const [stripeAccountLoading, setStripeAccountLoading] = useState(true);
  const { showMessage } = useActionMessage();

  useEffect(() => {
    const fetchStripeAccountId = async () => {
      try {
        setStripeAccountLoading(true); // Set loading state to true before the fetch
        const response = await createOrUpdateAccount(ownerId);
        setStripeAccountId(response.accountId);
        setIsStripeAccountComplete(response.isComplete);
        console.log('Stripe Account ID:', response.accountId);
      } catch (error: any) {
        showMessage(`Error: ${error.message}`, 'error');
      } finally {
        setStripeAccountLoading(false); // Ensure loading state is set to false after the fetch
      }
    };
    fetchStripeAccountId();
  }, [ownerId, showMessage]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (submitLoading) return;
    // Submit the bid
    try {
      setSubmitLoading(true);
      // Construct the bid data
      const bidData = {
        owner_id: ownerId || '',
        bidder_id: bidderId || '',
        lease_id: leaseId || '',
        chat_id: chatId || '',
        start_date: startDate,
        end_date: endDate,
        price: parseFloat(price),
        price_unit: priceUnit,
        num_beds_for_bid: parseInt(numBeds.toString(), 10),
        is_live: true,
      };

      // Submit the bid using the service function
      const success = await makeOffer(bidData);
      if (success) {
        callBackToChat();
        showMessage('Offer is now live', 'success');
      }
    } catch (error: any) {
      showMessage(`Error: ${error.message}`, 'error');
    } finally {
      setSubmitLoading(false);
    }
  };

  const handlerescindLiveOffers = async () => {
    try {
      setDeleteLoading(true);
      // Submit the bid using the service function
      const success = await rescindLiveOffers(
        chatId,
        ownerId,
        bidderId,
        leaseId
      );
      if (success) {
        // Show success message and return to chat
        showMessage(success.message, 'success');
        callBackToChat(); // Move back to chat after rescinding
      } else {
        // If rescinding failed, show a message
        showMessage('Failed to rescind the offer. Please try again.', 'error');
      }
    } catch (error: any) {
      // Handle unexpected errors and display the message
      showMessage(
        `Error: ${error.message || 'Unknown error occurred.'}`,
        'error'
      );
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        mt={1}
        p={1}
        display="flex"
        alignItems="center"
        sx={{
          border: `1px solid ${brand[500]}`,
          borderRadius: '10px',
          bgcolor: brand[50],
        }}
      >
        <InfoOutlinedIcon sx={{ marginRight: '8px' }} color="primary" />
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '14px',
          }}
        >
          Submitting an offer allows the host to proceed to payment for this
          sublease. You can delete the offer at any time.
        </Typography>
      </Box>
      {isStripeAccountComplete ? (
        <Box
          p={1}
          mb={1}
          display="flex"
          alignItems="center"
          sx={{
            border: `1px solid #4CAF50`, // Green outline
            borderRadius: '10px',
            bgcolor: '#E8F5E9', // Light green background
          }}
        >
          <CheckIcon sx={{ marginRight: '8px' }} color="success" />
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '14px',
            }}
          >
            Payout setup is complete. You’re ready to receive payments.
          </Typography>
        </Box>
      ) : (
        <Box
          p={1}
          mb={1}
          display="flex"
          alignItems="center"
          sx={{
            border: `1px solid #D32F2F`, // Darker red outline
            borderRadius: '10px',
            bgcolor: '#FFEBEE', // Brighter red background
          }}
        >
          <WarningAmberOutlined sx={{ marginRight: '8px' }} color="error" />
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '14px',
            }}
          >
            Setup your payout details to receive payments.
          </Typography>
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled={submitLoading}
            inputProps={{
              min: new Date().toISOString().split('T')[0],
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled={submitLoading}
            inputProps={{
              min: new Date().toISOString().split('T')[0],
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={7}>
              <TextField
                label="Price"
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                fullWidth
                disabled={submitLoading}
                InputProps={{
                  inputProps: { min: 0 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoney />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Unit Price"
                select
                value={priceUnit}
                onChange={(e) => setPriceUnit(e.target.value)}
                fullWidth
                disabled={submitLoading}
              >
                <MenuItem value="week">Per week</MenuItem>
                <MenuItem value="month">Per month</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Number of Beds"
            type="number"
            value={numBeds}
            onChange={(e) => setNumBeds(e.target.value)}
            fullWidth
            disabled={submitLoading}
            select
          >
            {' '}
            {Array.from({ length: selectBeds }, (_, index) => index + 1).map(
              (value) => (
                <MenuItem key={value} value={value} sx={{ m: '5px ' }}>
                  {value}
                </MenuItem>
              )
            )}
          </TextField>
        </Grid>
      </Grid>
      <EmbeddedStripeOnboarding
        accountId={stripeAccountId}
        isStripeAccountComplete={isStripeAccountComplete}
        isAccountLoading={stripeAccountLoading}
      />
      {/* Buttons Row */}
      <Grid container spacing={1} sx={{ justifyContent: 'flex-end' }}>
        <Grid item>
          <Button
            onClick={handlerescindLiveOffers}
            variant="text"
            sx={{ color: 'grey' }}
            disabled={deleteLoading}
            size="medium"
          >
            {deleteLoading ? <CircularProgress size={24} /> : 'Delete Offer'}
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={submitLoading}
            size="medium" // Make the buttons small
          >
            {submitLoading ? <CircularProgress size={24} /> : 'Submit Offer'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewForm;
