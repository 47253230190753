import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Skeleton,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { VerifiedUser, Lock } from '@mui/icons-material';
import LeaseDetailsTab from './tabs/LeaseDetailsTab';
import IncomingBids from '../bids/IncomingBids';
import Chat from '../chat/Chat';
import { Lease } from '../types/Lease';
import { auth } from '../../config/firebase-config';
import { getLease } from '../../api/leaseService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getBid } from '../../api/interactionService';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getStreetName } from '../utils/formatAddress';
import ShareButton from './ShareButton';
import { getSubscription } from '../../api/paymentService';
import { set, sub } from 'date-fns';
import PaymentTab from '../payments/PaymentTab';
import { subscribe } from 'diagnostics_channel';

interface LeaseDetailsModalProps {
  onFormChange: () => void;
}

const LeaseDetailsModal = ({ onFormChange }: LeaseDetailsModalProps) => {
  const { leaseId, chatId } = useParams();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  const [bidData, setBidData] = useState<any | null>(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState<any | null>(
    null
  );
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [lease, setLease] = useState<Lease | undefined>(undefined);
  const [isOwner, setIsOwner] = useState(false);
  const [currentChatId, setCurrentChatId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        console.log(lease);
        if (!leaseId) return; // Prevent unnecessary API call
        const subscription = await getSubscription(leaseId);

        if (subscription) {
          console.log('Subscription details:', subscription);
          setSubscriptionDetails(subscription);
          setSubscriptionActive(true);
        } else {
          console.log('No active subscription found.');
        }
      } catch (error) {
        console.error('Error fetching subscription:', error);
      }
    };
    // Fetch Lease and then Fetch Bid Data
    const fetchLeaseAndBidData = async () => {
      setLoading(true);

      try {
        // Fetch Lease
        const leaseResponse = await getLease(leaseId || '');
        setLease(leaseResponse);
        setIsOwner(leaseResponse?.owner_id === user?.uid);
        fetchSubscription(); //TODO owner doesn't fetch bid data?

        if (chatId) {
          setCurrentChatId(chatId);
        }
        // Fetch Bid Data if lease is successfully fetched
        else if (leaseResponse && user && leaseResponse.owner_id !== user.uid) {
          const bidResponse = await getBid(
            leaseResponse.owner_id || '',
            user.uid || '',
            leaseId || ''
          );

          if (bidResponse) {
            setBidData(bidResponse);
            setCurrentChatId(bidResponse.chat_id || '');
          }
        }
      } catch (error) {
        console.error('Error fetching lease or bid data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLeaseAndBidData();
  }, [leaseId, user, chatId]);

  const getTabIndex = () => {
    if (subscriptionActive) {
      if (location.pathname.includes('/chat/')) return 1;
      if (location.pathname.includes('/payments')) return 2;
    } else {
      if (location.pathname.includes('/chat/')) return isOwner ? 2 : 1;
      if (location.pathname.includes('/payments')) return isOwner ? 2 : 1;
      if (location.pathname.includes('/incoming-offers')) return 1;
    }
    return 0;
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    // Update the URL based on the active tab
    if (newValue === 0) {
      navigate(`/posts/${leaseId}`);
    } else if (subscriptionActive) {
      if (newValue === 1) {
        navigate(`/posts/${leaseId}/chat/${subscriptionDetails.chat_id}`);
      } else if (newValue === 2) {
        navigate(`/posts/${leaseId}/payments`);
      }
    } else {
      //
      if (newValue === 1 && isOwner) {
        navigate(`/posts/${leaseId}/incoming-offers`);
      } else if (
        ((newValue === 2 && isOwner) || (newValue === 1 && !isOwner)) &&
        currentChatId
      ) {
        navigate(`/posts/${leaseId}/chat/${currentChatId}`);
      }
    }
    // if (newValue === 0) {
    //   navigate(`/posts/${leaseId}`);
    // } else if (newValue === 1 && isOwner) {
    //   navigate(`/posts/${leaseId}/incoming-offers`);
    // } else if (
    //   ((newValue === 2 && isOwner) || (newValue === 1 && !isOwner)) &&
    //   currentChatId
    // ) {
    //   navigate(`/posts/${leaseId}/chat/${currentChatId}`);
    // } else if (
    //   ((newValue === 2 && isOwner) || (newValue === 2 && !isOwner)) &&
    //   subscriptionActive
    // ) {
    //   navigate(`/posts/${leaseId}/payments`);
    // }
  };

  // TODO update
  if (loading) {
    return <div></div>;
  } else if (!lease) {
    return <div> Page Doesn't Exist </div>;
  }

  const onClose = () => navigate('/');

  const renderSkeletons = () => (
    <>
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={400}
        sx={{ borderRadius: '10px', mb: 2 }}
      />
      <Skeleton
        animation="wave"
        variant="text"
        height={30}
        sx={{ mb: 1, width: '80%' }}
      />
      <Skeleton
        animation="wave"
        variant="text"
        height={20}
        sx={{ mb: 1, width: '50%' }}
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={250}
        sx={{ borderRadius: '10px', mb: 2 }}
      />
    </>
  );

  /**
   * Render the content inside the modal based on active tab and data.
   */
  const renderActiveTab = () => {
    const tabIndex = getTabIndex();

    // 0 -> Lease Details
    if (tabIndex === 0) {
      return (
        <LeaseDetailsTab
          lease={lease}
          bidData={bidData}
          goToChat={(id) => navigate(`/posts/${leaseId}/chat/${id}`)}
          bidExists={!!bidData}
          subscribed={subscriptionActive}
          onFormChange={onFormChange}
        />
      );
    }

    // If subscription is active, tab 1 -> Chat, tab 2 -> Payments
    if (subscriptionActive) {
      if (tabIndex === 1) {
        return (
          <Chat
            chatId={subscriptionDetails?.chat_id || ''}
            leaseId={leaseId || ''}
            isSubscriptionActive={subscriptionActive}
          />
        );
      } else if (tabIndex === 2) {
        return <PaymentTab subscription={subscriptionDetails} />;
      }
    } else {
      // Not subscribed
      // tabIndex 1 (owner) => Incoming offers
      // tabIndex 1 (not owner) => Chat
      // tabIndex 2 (owner) => Chat
      if (tabIndex === 1 && isOwner) {
        return (
          <IncomingBids
            lease={lease}
            goToChat={(id) => navigate(`/posts/${leaseId}/chat/${id}`)}
          />
        );
      } else if (tabIndex === 1 && !isOwner && currentChatId) {
        return (
          <Chat
            chatId={currentChatId}
            leaseId={leaseId || ''}
            isSubscriptionActive={subscriptionActive}
          />
        );
      } else if (tabIndex === 2 && isOwner && currentChatId) {
        return (
          <Chat
            chatId={currentChatId}
            leaseId={leaseId || ''}
            isSubscriptionActive={subscriptionActive}
          />
        );
      }
    }

    return null;
  };

  return (
    <Modal open onClose={onClose} aria-labelledby="lease-details-title">
      <Box
        sx={{
          borderRadius: '10px',
          border: '1px solid',
          borderColor: 'divider',
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '100%', sm: '80%', md: '70%', lg: '50%' }, // Responsive width
          maxHeight: { xs: '100vh', sm: '93vh' },
          overflow: 'auto', // Allow vertical scrolling
          bgcolor: 'background.paper',
          boxShadow: `0 0 100px 10px rgb(156, 204, 252)`, // Adds a blue glow
          p: { xs: 2, sm: 4 },
          pt: { xs: 8 },
          pb: { xs: 7 },
          outline: 0, // Remove default focus outline
          // ...(!user && {
          //   overflow: 'hidden',
          // }),
        }}
      >
        {/* Close Button Row */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: -2,
            mb: 1,
          }}
        >
          <ShareButton leaseId={leaseId || ''} /> {/* Add Share button */}
          <IconButton sx={{ zIndex: 1 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {/* Address & Tabs */}
        {loading ? (
          renderSkeletons()
        ) : (
          <>
            <Typography
              id="lease-details-title"
              variant="h6"
              component="h2"
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 600,
                fontSize: 24,
                color: 'primary.main',
                marginBottom: 2,
              }}
            >
              {!user
                ? `Place on ${getStreetName(lease.address)}`
                : lease.address.split(',')[0]}
              {lease.verified && (
                <Tooltip title="User is verified">
                  <VerifiedUser
                    sx={{
                      color: '#1976d2',
                      fontSize: '2rem',
                      verticalAlign: 'text-top',
                      ml: 1,
                    }}
                  />
                </Tooltip>
              )}
            </Typography>

            <Tabs
              value={getTabIndex()}
              onChange={handleTabChange}
              aria-label="Lease details tabs"
              sx={{ marginBottom: 2 }}
            >
              <Tab label="Lease Details" />
              {isOwner && !subscriptionDetails && (
                <Tab label="Incoming Offers" />
              )}
              <Tab
                label="Chat"
                disabled={(isOwner || !bidData) && !subscriptionActive}
              />
              <Tab label="Payments" disabled={!subscriptionActive} />
            </Tabs>
            {/* {getTabIndex() === 0 && (
              <LeaseDetailsTab
                lease={lease}
                bidData={bidData}
                //setCurrentChatId={setCurrentChatId}
                goToChat={(chatId) =>
                  navigate(`/posts/${leaseId}/chat/${chatId || currentChatId}`)
                }
                bidExists={!!bidData}
                subscribed={subscriptionActive}
                onFormChange={onFormChange}
              />
            )}
            {getTabIndex() === 1 &&
              isOwner &&
              (subscriptionActive && subscriptionDetails.chat_id ? (
                <Chat
                  chatId={subscriptionDetails.chat_id}
                  leaseId={leaseId || ''}
                  isSubscriptionActive={subscriptionActive}
                />
              ) : (
                <IncomingBids
                  lease={lease}
                  goToChat={(chatId) =>
                    navigate(`/posts/${leaseId}/chat/${chatId}`)
                  }
                />
              ))}

            {getTabIndex() === 1 && !isOwner && currentChatId && (
              <Chat
                chatId={currentChatId}
                leaseId={leaseId || ''}
                isSubscriptionActive={subscriptionActive}
              />
            )}
            {getTabIndex() === 2 && isOwner && !subscriptionActive && (
              <Chat
                chatId={currentChatId}
                leaseId={leaseId || ''}
                isSubscriptionActive={subscriptionActive}
              />
            )}
            {getTabIndex() === 2 && subscriptionActive && (
              <PaymentTab subscription={subscriptionDetails} />
            )} */}

            {renderActiveTab()}
          </>
        )}
        {/* Lock Screen */}
        {/* {!user && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.8)', // Slightly transparent white background
              backdropFilter: 'blur(5px)', // Blur effect
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: 1,
              zIndex: 2, // Ensure it's on top of other elements
              height: '100%',
            }}
          >
            <Lock sx={{ fontSize: 80, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" sx={{ mb: 2 }}>
              Please Sign In/Sign Up to view dublease
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate('/Login');
              }}
            >
              Sign In or Sign Up
            </Button>
          </Box>
        )} */}
      </Box>
    </Modal>
  );
};

export default LeaseDetailsModal;
