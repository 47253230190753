import React, { useEffect, useState } from 'react';
import { Box, Grid, Pagination, Skeleton, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate, Outlet } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../config/firebase-config';
import { Lease } from '../types/Lease';
import { getLeases } from '../../api/leaseService';
import { getOwnerLeases } from '../../api/interactionService';
import LeaseFilter from './Filters';
import LeaseCard from './LeaseCard';

interface LeaseDisplayProps {
  refreshLeases: boolean;
}

const LeaseDisplay: React.FC<LeaseDisplayProps> = ({ refreshLeases }) => {
  const [user] = useAuthState(auth);
  const [leases, setLeases] = useState<Lease[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Filter States
  const [myListings, setMyListings] = useState<boolean>(false);
  const [seeBids, setSeeBids] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [location, setLocation] = useState<string | null>(null);
  const [numBeds, setNumBeds] = useState<number | ''>('');
  const [numRoommates, setNumRoommates] = useState<number | ''>('');
  const [sortPrice, setSortPrice] = useState<string>('');
  const [available, setAvailable] = useState<string>('asc');

  // Fetch Leases
  useEffect(() => {
    const loadLeases = async () => {
      const minimumLoadingTime = 500;
      const startTime = Date.now();
      setLoading(true);
      try {
        const filters = {
          startDate,
          endDate,
          location,
          numBeds,
          numRoommates,
          sortPrice,
          seeBids,
          available,
        };
        // const ownerLeases = await getOwnerLeases(user?.uid || '');

        let posts = [];
        let totalCount = 0;

        if (myListings) {
          const ownerLeases = await getOwnerLeases(user?.uid || '');
          posts = ownerLeases || [];
          totalCount = ownerLeases.length;
        } else {
          const otherPosts = await getLeases(page, filters);
          posts = otherPosts.posts || [];
          totalCount = otherPosts.totalCount || 0;
        }

        setTotalPages(Math.ceil(totalCount / 12)); // 12 items per page
        //setLeases([...ownerLeases, ...posts]);
        setLeases(posts);
      } catch (error) {
        console.error('Error loading leases:', error);
      } finally {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = minimumLoadingTime - elapsedTime;
        setTimeout(() => setLoading(false), Math.max(remainingTime, 0));
      }
    };

    loadLeases();
  }, [
    page,
    user,
    startDate,
    endDate,
    location,
    numBeds,
    numRoommates,
    sortPrice,
    myListings,
    seeBids,
    available,
    refreshLeases,
  ]);

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // Skeleton loading placeholders
  const renderSkeletons = () => (
    <Grid container spacing={2} sx={{ padding: '13px' }}>
      {[...Array(12)].map((_, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2.3} key={index}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={400}
            sx={{ borderRadius: '8px', width: { xs: '100%', sm: '95%' } }}
          />
        </Grid>
      ))}
    </Grid>
  );

  // Parent animation settings
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  // Child animation settings
  const cardVariants = {
    hidden: { opacity: 0, y: 10, scale: 0.95 },
    show: { opacity: 1, y: 0, scale: 1 },
    exit: { opacity: 0, y: -10 },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        mt: 5,
      }}
    >
      <LeaseFilter
        myListings={myListings}
        setMyListings={setMyListings}
        seeBids={seeBids}
        setSeeBids={setSeeBids}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        location={location}
        setLocation={setLocation}
        numBeds={numBeds}
        setNumBeds={setNumBeds}
        numRoommates={numRoommates}
        setNumRoommates={setNumRoommates}
        sortPrice={sortPrice}
        setSortPrice={setSortPrice}
        available={available}
        setAvailable={setAvailable}
      />
      <Box sx={{ flexGrow: 1, padding: { xs: 0, sm: 2 } }}>
        {loading ? (
          renderSkeletons()
        ) : leases.length > 0 ? (
          <>
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="show"
              exit="hidden"
            >
              <Grid container spacing={2} sx={{ padding: { xs: 0, sm: 1 } }}>
                {leases.map((lease) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2.3}
                    key={lease.id}
                    style={{ paddingBottom: '20px' }}
                  >
                    <motion.div
                      variants={cardVariants}
                      transition={{ duration: 0.6, ease: 'easeInOut' }}
                      style={{
                        width: '100%',
                        maxWidth: '345px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <LeaseCard
                        lease={lease}
                        onClick={() => navigate(`/posts/${lease.id}`)}
                        // bidExists={lease.has_bid}
                      />
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </motion.div>
            {totalPages > 1 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingBottom: 2,
                  paddingTop: 1,
                }}
              >
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Box>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              padding: { xs: 2, sm: 20 },
            }}
          >
            <Typography variant="h4" color="textSecondary" gutterBottom>
              No leases available yet.
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Be the first to post a lease and help others find their next home!
            </Typography>
          </Box>
        )}
      </Box>
      <Outlet />
    </Box>
  );
};

export default LeaseDisplay;
