import React from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { brand } from '../../../theme/theme';
import { auth } from '../../../config/firebase-config';
//import { useNavigate } from 'react-router-dom';

interface PersonalInformationProps {
  profileData: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({
  profileData,
  handleChange,
  handleCheckboxChange,
}) => {
  //const navigate = useNavigate();

  return (
    <>
      <Typography
        id="modal-title"
        variant="h6"
        sx={{ color: brand[500] }}
        component="h2"
        gutterBottom
      >
        Personal Information
      </Typography>
      <Typography
        id="modal-description"
        variant="subtitle1"
        color="textSecondary"
        sx={{ mb: 2, fontStyle: 'italic' }}
        gutterBottom
      >
        Only your first name and profile picture will be visible to other users
        by default
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Email"
            name="email"
            value={
              auth.currentUser?.email ||
              'You must sign in/sign up to update your profile'
            }
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="First Name"
            name="first_name"
            value={profileData.first_name}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Last Name"
            name="last_name"
            value={profileData.last_name}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Phone Number"
            name="phone_number"
            type="number"
            inputProps={{ maxLength: 10 }}
            value={profileData.phone_number}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <TextField
            label="Student ID Number"
            name="student_id"
            type="number"
            inputProps={{ maxLength: 10 }}
            value={profileData.student_id}
            onChange={handleChange}
            fullWidth
          />
        </Grid> */}
        <Grid item xs={12} mt={1}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" sx={{ mr: 2, fontWeight: 600 }}>
              Notifications:
            </Typography>
            <Box>
              {/* <FormControlLabel
                control={
                  <Switch
                    checked={profileData.text_notifications_on || false}
                    onChange={handleCheckboxChange}
                    name="text_notifications_on"
                    color="primary"
                  />
                }
                label="Text"
                labelPlacement="end"
                sx={{
                  alignItems: 'center',
                  '.MuiTypography-root': {
                    fontSize: '0.9rem',
                    fontWeight: 500,
                  },
                }}
              /> */}
              <FormControlLabel
                control={
                  <Switch
                    checked={profileData.email_notifications_on || false}
                    onChange={handleCheckboxChange}
                    name="email_notifications_on"
                    color="primary"
                  />
                }
                label="Email"
                labelPlacement="end"
                sx={{
                  alignItems: 'center',
                  '.MuiTypography-root': {
                    fontSize: '0.9rem',
                    fontWeight: 500,
                  },
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PersonalInformation;
