import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Tooltip,
  Chip,
} from '@mui/material';
import { VerifiedUser } from '@mui/icons-material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { Lease } from '../types/Lease';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../theme/global.css';
import { auth } from '../../config/firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getStreetName } from '../utils/formatAddress';
import { brand, green, red } from '../../theme/theme';
import { formatDate } from '../utils/formatTimestamps';
interface LeaseCardProps {
  lease: Lease;
  // bidExists: boolean;
  onClick: (lease: Lease) => void;
}

const LeaseCard = ({ lease, onClick }: LeaseCardProps) => {
  const [user] = useAuthState(auth);
  const bidExists = Boolean(lease.has_bid);
  const isActive = Boolean(lease.is_active);
  const isBooked = Boolean(lease.booked);
  const outsideViewer =
    !user || (lease.host_id !== user.uid && lease.guest_id !== user.uid);

  return (
    <Card
      raised
      sx={{
        width: { xs: '100%', sm: '95%' },
        overflow: 'hidden',
        borderRadius: '20px',
        backgroundColor: 'transparent',
        backdropFilter: 'blur(24px)',
        boxShadow: 5,
        borderColor: 'divider',
        cursor: 'pointer',
      }}
    >
      <div style={{ position: 'relative' }}>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={0}
          slidesPerView={1}
          loop
          style={{ width: '100%', height: '250px' }}
        >
          {lease.image_urls && lease.image_urls.length > 0 ? (
            lease.image_urls.map((image, idx) => (
              <SwiperSlide key={idx}>
                <img
                  src={image}
                  alt={`Lease ${idx + 1}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '10px 10px 0 0',
                  }}
                  loading="lazy"
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <img
                src="/No_Image_Available.jpg"
                alt="User uploaded 0 images"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '10px 10px 0 0',
                }}
              />
            </SwiperSlide>
          )}
        </Swiper>
      </div>
      <CardContent sx={{ padding: '16px' }} onClick={() => onClick(lease)}>
        <Typography variant="h6" color="primary" gutterBottom noWrap>
          {!user
            ? `Place on ${getStreetName(lease.address)}`
            : lease.address.split(',')[0]}
          {lease.verified && (
            <Tooltip title="User is verified">
              <VerifiedUser
                sx={{
                  color: '#1976d2',
                  fontSize: '1.3rem', // Smaller size
                  verticalAlign: 'text-top', // Align with the text
                  ml: 1,
                }}
              />
            </Tooltip>
          )}
          {!isActive && bidExists && (
            <Chip
              label="Active Offer"
              color="info"
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                fontSize: '13px',
                zIndex: 10,
                fontWeight: 'bold',
                backgroundColor: brand[50], // Set background color to white
                color: brand[400], // Set text color to blue
                border: '1px solid #1976d2', // Optional: Add a border with blue color
              }}
            />
          )}
          {isActive && (
            <Chip
              label="In Progress"
              color="success"
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                fontSize: '13px',
                zIndex: 10,
                fontWeight: 'bold',
                backgroundColor: green[50], // Light green background
                color: green[500], // Mid-green text color
                border: `1px solid ${green[500]}`, // Border with main gree
              }}
            />
          )}
          {isBooked && outsideViewer && (
            <Chip
              label="Booked"
              color="error"
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                fontSize: '13px',
                zIndex: 10,
                fontWeight: 'bold',
                backgroundColor: red[50], // Light green background
                color: red[500], // Mid-green text color
                border: `1px solid ${red[500]}`, // Border with main gree
              }}
            />
          )}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          {lease.address.split(',').slice(1).join(', ')}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          {formatDate(lease.start_date)} - {formatDate(lease.end_date)}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          {lease.num_rooms_for_lease} Bedroom(s) -{' '}
          <span style={{ fontWeight: 'bold' }}>${lease.price}</span> /{' '}
          {lease.price_unit}
        </Typography>
      </CardContent>
      <CardContent
        onClick={() => onClick(lease)}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0px 15px 5px 5px',
        }}
      >
        {lease.owner_id === (user?.uid || '') ? (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => onClick(lease)}
          >
            View My Listing
          </Button>
        ) : (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => onClick(lease)}
            disabled={isBooked && outsideViewer}
          >
            View Details
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default LeaseCard;
